module.exports = [{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Parisienne","file":"https://fonts.googleapis.com/css2?family=Parisienne&display=swap"},{"name":"Chocolate Classical Sans","file":"https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&display=swap"},{"name":"Jost","file":"https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap"},{"name":"Playwrite AU VIC","file":"https://fonts.googleapis.com/css2?family=Playwrite+AU+VIC:wght@100..400&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"44d534c8c188bf261bfce12a1e83d708"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
